import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import styled, { css, keyframes } from "styled-components";
import { colors, mediaquery } from "src/styles/variables";
import Button from "src/atoms/Button";
import { HeaderL, Body } from "src/atoms/Typography";
import { InView } from "react-intersection-observer";
import PageContext from "src/stores/Page";
import ProgressBarTracker from "src/atoms/ProgressBarTracker";
import { Container, Col, Row } from "react-awesome-styled-grid";
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";
import RichText from "src/atoms/RichText";

import TrackerQuestions from "./TrackerQuestions";
import TrackerCategories from "./TrackerCategories";

import getImage from "src/utils/getImage";
import Picture, { FITS, TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import Settings from "src/stores/Settings";

const Tracker = ({
  id,
  title,
  trackerDescription,
  imageDesktop,
  imageMobile,
  introductionSlideText,
  introductionSlideImageDesktop,
  introductionSlideImageMobile,
  categories,
  thanksSlideText,
  thanksSlideImageDesktop,
  thanksSlideImageMobile,
  resultPageSlug,
}) => {
  const TrackerListRef = useRef(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [LatestItemIndex, setLatestItemIndex] = useState(0);
  const [ioMargin, setIoMargin] = useState(0);
  const {
    //transparentHeader: [, setTransparentHeader],
    forceVisibleHeader: [, setForceVisibleHeader],
    headerHeight: [headerHeight],
  } = useContext(PageContext);

  const { locale } = useContext(Settings);
  const { nextTitle, previousTitle, showTrackerResultsTitle } = useContext(
    Settings
  ).translations;

  const trackerListItemsArr = [].concat(
    ...categories.map((item) => {
      return [
        item.id,
        ...item.listOfQuestions.map((subItem) => {
          return subItem.id;
        }),
      ];
    })
  );

  const trackerProgressSegmentsCount = categories.length + 1;
  let accTrackerProgress = 100 / trackerProgressSegmentsCount / 2;
  const trackerProgressArray = [
    { id: "tracker-start", type: "category", progress: 0 },
    {
      id: "tracker-intro",
      type: "question",
      progress: accTrackerProgress,
    },
  ]
    .concat(
      ...categories.map((item, index) => {
        const progress =
          index === 0
            ? 100 / trackerProgressSegmentsCount / 2
            : 100 /
              trackerProgressSegmentsCount /
              (categories[index - 1].listOfQuestions.length + 1);
        accTrackerProgress += progress;
        return [
          { id: item.id, type: "category", progress: accTrackerProgress },
          ...item.listOfQuestions.map((subItem, index) => {
            const progress =
              100 /
              trackerProgressSegmentsCount /
              (item.listOfQuestions.length + 1);
            accTrackerProgress += progress;
            return {
              id: subItem.id,
              type: "answer",
              progress: accTrackerProgress,
            };
          }),
        ];
      })
    )
    .concat([{ id: "tracker-thanks", type: "category", progress: 100 }]);

  const disableNextBtn = (activeItemIndex) => {
    return (
      activeItemIndex !== 0 &&
      activeItemIndex !== 1 &&
      trackerProgressArray[activeItemIndex].type !== "category" &&
      activeItemIndex > LatestItemIndex
    );
  };

  const ScrollToElement = (elemIndex) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    TrackerListRef.current?.scrollTo({
      //top: document.getElementById(`tracker_item_${elemIndex}`)?.offsetTop,
      left: document.getElementById(`tracker_item_${elemIndex}`)?.offsetLeft,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    ScrollToElement(activeItemIndex);
  }, [activeItemIndex]);

  const findElementIndex = (elemId) => {
    if (!TrackerListRef.current) return;
    return trackerListItemsArr.indexOf(elemId) + 1;
  };

  useEffect(() => {
    setIoMargin(window.innerHeight - headerHeight);
  }, [headerHeight]);

  const answersCategories = {};
  categories.map((item) => {
    answersCategories[item.id] = [];
  });
  const [answers, setAnswers] = useState(answersCategories);
  let newAnswersArr = answersCategories;

  const setSelectedAnswer = (id, questionId, answerIndex, score) => {
    newAnswersArr = answers;
    const newsAnswersObj = { questionId, answerIndex, score };
    const arrayIndex = newAnswersArr[id].findIndex(
      (subItem) => subItem.questionId === questionId
    );

    if (arrayIndex !== -1) {
      newAnswersArr[id].splice(arrayIndex, 1);
    }
    newAnswersArr[id].push(newsAnswersObj);
    setAnswers(newAnswersArr);
    if (activeItemIndex > LatestItemIndex) {
      setLatestItemIndex(activeItemIndex);
    }
    /*
    @TODO we need to read this in and set it as answers if it exists
    if client refreshes page closes browser etc.
    */
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const calculateScores = () => {
    let queryStr = "?";

    categories.map((category, index) => {
      const categoryScore = answers[category.id].reduce((acc, curr) => {
        return acc + parseInt(curr.score, 10);
      }, 0);
      queryStr += `${category.id}=${Math.round(
        categoryScore * category.categoryScoreFactor
      )}${index !== categories.length - 1 ? "&" : ""}`;
    });

    navigate(
      `${locale.currentLanguage.pathPrefix ?? ""}${resultPageSlug}${queryStr}`,
      {
        state: {
          trackerUrl: window.location.href,
        },
      }
    );
  };

  return (
    <InView
      rootMargin={`0px 0px -${ioMargin}px 0px`}
      onChange={(inView) => {
        //setTransparentHeader(inView);
        setForceVisibleHeader(inView);
      }}
    >
      <ProgressBarWrapper activeItemIndex={activeItemIndex}>
        <Container>
          <NoMarginRowStyled>
            <Col md={10} offset={{ md: 3 }}>
              <ProgressBarTracker
                activeItemIndex={activeItemIndex}
                categories={categories}
                trackerProgressItem={trackerProgressArray[activeItemIndex]}
              />
            </Col>
          </NoMarginRowStyled>
        </Container>
      </ProgressBarWrapper>
      <TrackerWrapper>
        <TrackerQuestionsWrapper>
          <TrackerList ref={TrackerListRef}>
            <TrackerListItem id="tracker_item_0">
              <PictureWrapper
                fit={FITS.cover}
                small={imageMobile}
                large={imageDesktop}
              />
              <HeaderCurvedStyled
                fixedHeight={false}
                backgroundColor={colors.activiaGreen}
              >
                <Container>
                  <NoMarginRowStyled>
                    <Col md={10} offset={{ md: 3 }}>
                      <ContainerAnimation>
                        {title && <SlideHeader as="h1">{title}</SlideHeader>}
                        {trackerDescription && (
                          <SlideDescription>
                            {trackerDescription}
                          </SlideDescription>
                        )}
                      </ContainerAnimation>
                    </Col>
                  </NoMarginRowStyled>
                </Container>
              </HeaderCurvedStyled>
            </TrackerListItem>
            <TrackerListItem id="tracker_item_1">
              {introductionSlideImageMobile &&
                introductionSlideImageDesktop && (
                  <PictureWrapper
                    fit={FITS.cover}
                    small={introductionSlideImageMobile}
                    large={introductionSlideImageDesktop}
                  />
                )}
              <HeaderCurvedStyled
                fixedHeight={false}
                backgroundColor={colors.activiaGreen}
              >
                <Container>
                  <NoMarginRowStyled>
                    <Col md={10} offset={{ md: 3 }}>
                      {introductionSlideText && (
                        <RichTextWrapper>
                          <RichText
                            as="div"
                            enableRenderer
                            headColor={colors.white}
                            bodyColor={colors.white}
                            doc={introductionSlideText.value}
                          />
                        </RichTextWrapper>
                      )}
                    </Col>
                  </NoMarginRowStyled>
                </Container>
              </HeaderCurvedStyled>
            </TrackerListItem>
            {categories?.map((item, index) => {
              return (
                <Fragment key={`tracker_item_${item.id}`}>
                  <TrackerListItem
                    id={`tracker_item_${findElementIndex(item.id) + 1}`}
                  >
                    <TrackerCategories
                      backgroundColor={item.colour}
                      title={`${index + 1}. ${item.title}`}
                      subtitle={item.subtitle}
                      description={item.description}
                      imageMobile={getImage(
                        item.image.small,
                        PICTURE_TYPES.fluid
                      )}
                      imageDesktop={getImage(
                        item.image.large,
                        PICTURE_TYPES.fluid
                      )}
                    />
                  </TrackerListItem>
                  {item?.listOfQuestions?.map((subItem, index) => {
                    return (
                      <TrackerListItem
                        key={`tracker_item_${item.id}_question_${subItem.id}`}
                        id={`tracker_item_${findElementIndex(subItem.id) + 1}`}
                      >
                        <TrackerQuestions
                          category={item.title}
                          backgroundColor={item.colour}
                          title={subItem.title}
                          questionDescription={subItem.questionDescription}
                          questionAnswers={subItem.questionAnswers}
                          setSelectedAnswer={setSelectedAnswer}
                          categoryId={item.id}
                          questionId={subItem.id}
                          questionIndex={index + 1}
                          questionsCount={item.listOfQuestions.length}
                        />
                      </TrackerListItem>
                    );
                  })}
                </Fragment>
              );
            })}
            <TrackerListItem
              id={`tracker_item_${trackerListItemsArr.length + 2}`}
            >
              {thanksSlideImageMobile && thanksSlideImageDesktop && (
                <PictureWrapper
                  fit={FITS.cover}
                  small={thanksSlideImageMobile}
                  large={thanksSlideImageDesktop}
                />
              )}
              <HeaderCurvedStyled fixedHeight={false}>
                <Container>
                  <NoMarginRowStyled>
                    <Col md={10} offset={{ md: 3 }}>
                      {thanksSlideText && (
                        <RichTextWrapper>
                          <RichText
                            as="div"
                            enableRenderer
                            headColor={colors.white}
                            bodyColor={colors.white}
                            doc={thanksSlideText.value}
                          />
                        </RichTextWrapper>
                      )}
                    </Col>
                  </NoMarginRowStyled>
                </Container>
              </HeaderCurvedStyled>
            </TrackerListItem>
          </TrackerList>
        </TrackerQuestionsWrapper>
        <BtnsContainer>
          <NoMarginRowStyled>
            <Col md={10} offset={{ md: 3 }}>
              <CTAButtonWrapper>
                {activeItemIndex !== 0 && activeItemIndex !== -1 && (
                  <ButtonStyled
                    onClick={() => {
                      setActiveItemIndex(activeItemIndex - 1);
                    }}
                  >
                    {previousTitle}
                  </ButtonStyled>
                )}
                {activeItemIndex < trackerListItemsArr.length + 2 && (
                  <ButtonStyled
                    disabled={disableNextBtn(activeItemIndex)}
                    onClick={() => {
                      setActiveItemIndex(activeItemIndex + 1);
                    }}
                  >
                    {nextTitle}
                  </ButtonStyled>
                )}
                {activeItemIndex === trackerListItemsArr.length + 2 && (
                  <ButtonStyled onClick={calculateScores}>
                    {showTrackerResultsTitle}
                  </ButtonStyled>
                )}
              </CTAButtonWrapper>
            </Col>
          </NoMarginRowStyled>
        </BtnsContainer>
      </TrackerWrapper>
    </InView>
  );
};

Tracker.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  trackerDescription: PropTypes.string.isRequired,
  introductionSlideText: PropTypes.object,
  introductionSlideImageMobile: PropTypes.object,
  introductionSlideImageDesktop: PropTypes.object,
  imageDesktop: PropTypes.object,
  imageMobile: PropTypes.object,
  categories: PropTypes.arrayOf(TrackerCategories).isRequired,
  thanksSlideText: PropTypes.object,
  thanksSlideImageDesktop: PropTypes.object,
  thanksSlideImageMobile: PropTypes.object,
  resultPageSlug: PropTypes.string,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  animation: 1s ${fadeIn} cubic-bezier(0.47, 0, 0.37, 1) forwards;
`;

const ContainerAnimation = styled.div`
  ${fadeInAnimation};
`;

const NoMarginRowStyled = styled(Row)``;

const ProgressBarWrapper = styled.div`
  position: relative;
  top: calc(2rem + 80px);
  z-index: 2;
  height: 20px;
  opacity: ${({ activeItemIndex }) => (activeItemIndex === 0 ? 0 : 1)};
  transition: all 0.3s;

  > div {
    width: 100%;
  }

  ${mediaquery.lg(css`
    top: calc(2rem + 85px);
  `)}
`;

const TrackerWrapper = styled.div`
  color: ${colors.white};
  width: 100%;
  margin-top: -20px;
  position: relative;
`;

const HeaderCurvedStyled = styled(HeaderCurved)`
  margin-top: -100px;

  & > div {
    min-height: 0;
    padding-top: calc(2rem + 100px);
  }
`;

const PictureWrapper = styled(Picture)`
  position: absolute !important;
`;

const TrackerQuestionsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 100vh;
`;

const TrackerList = styled.ul`
  display: flex;
  width: 100%;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  padding-top: 100px;
`;

const TrackerListItem = styled.li`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
`;

const BtnsContainer = styled(Container)`
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

const CTAButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  position: relative;
  bottom: 1rem;
  width: 100%;
  z-index: 2;
  margin-top: -50px;
`;

const ButtonStyled = styled(Button)`
  transition: all 0.3s;
  pointer-events: all;

  &[disabled] {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px dashed ${colors.white};
  }
`;

const SlideHeader = styled(HeaderL)`
  padding: 1rem 0;

  ${mediaquery.md(css`
    padding: 1.5rem 0;
  `)}
`;

const SlideDescription = styled(Body)`
  display: block;
  margin-bottom: 2.5rem;
  font-size: 22px;
`;

const RichTextWrapper = styled.div`
  padding-top: 1rem;

  * {
    color: ${colors.white}!important;
  }

  ${mediaquery.md(css`
    padding-top: 1.5rem;
  `)}
`;

export default Tracker;
